<template>
    <TwoColumnsPage :title="bodyData.title" :subtitle="bodyData.tagline" :description="bodyData.description" :image="bodyData.imgPath">
        <template #right>
            <ContactFormSupport />
        </template>
    </TwoColumnsPage>
</template>

<script>
import TwoColumnsPage from '~/components/common/two-columns-page';
import ContactFormSupport from '~/components/common/contact-form-support';

import headMixins from '~/mixins/head-mixins';

export default {
    name: 'PSupport',
    components: { TwoColumnsPage, ContactFormSupport },
    mixins: [headMixins],
    async asyncData({ store }) {
        const pageContent = await store.dispatch('fetchPageContent', 'support');

        return {
            headData: pageContent.head,
            bodyData: pageContent.body
        };
    },
    data() {
        return {
            headData: null,
            bodyData: null
        };
    }
};
</script>
