<template>
    <ValidationObserver ref="supportFormRef" v-slot="{ handleSubmit }">
        <v-form :disabled="loading" @submit.prevent="handleSubmit(onSubmit)">
            <div class="row">
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="product"
                        rules="required"
                        class="form-group"
                    >
                        <ProductListSelectBox v-model="formData.product" :errors="errors" />
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="AWS Account ID"
                        rules="required|numeric"
                        class="form-group"
                    >
                        <v-text-field
                            v-model="formData.awsId"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            label="Provide AWS Account ID"
                            placeholder="Enter ID"
                            outlined
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="experiencing field"
                        rules="required|min_words:5"
                        class="form-group"
                    >
                        <v-textarea
                            id="experiencing"
                            v-model="formData.experiencing"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            class="form-control"
                            label="What issue are you experiencing?"
                            rows="5"
                            outlined
                            placeholder="Describe what issue you are experiencing"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="expectation field"
                        rules="required|min_words:5"
                        class="form-group"
                    >
                        <v-textarea
                            id="expectation"
                            v-model="formData.expectation"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            class="form-control"
                            label="What was your expectation?"
                            rows="5"
                            outlined
                            placeholder="Describe what result you are expecting"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="First Name"
                        rules="required"
                        class="form-group"
                    >
                        <v-text-field
                            v-model="formData.firstName"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            label="First Name"
                            placeholder="Enter your first name"
                            outlined
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Last Name"
                        rules="required"
                        class="form-group"
                    >
                        <v-text-field
                            v-model="formData.lastName"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            label="Last Name"
                            placeholder="Enter your last name"
                            outlined
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Company Name"
                        rules="required"
                        class="form-group"
                    >
                        <v-text-field
                            v-model="formData.companyName"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            label="Company Name"
                            placeholder="Enter your company name"
                            outlined
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Email"
                        rules="email|required"
                        class="form-group"
                    >
                        <v-text-field
                            v-model="formData.email"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            label="Email"
                            placeholder="Enter your Email Address"
                            outlined
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="checkbox"
                        rules="required"
                        class="checkbox-wrapper"
                    >
                        <v-checkbox
                            v-model="formData.isAgree"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                            value="true"
                            label="I agree to receive an email response."
                            class="mt-0 pt-0"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <SubmitButton :loading="loading" />
                </div>
            </div>
        </v-form>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import formMixin from '~/mixins/form-mixin';

export default {
    name: 'ContactFormSupport',
    components: {
        ProductListSelectBox: () => import('../product-list-select-box/product-list-select-box'),
        ValidationProvider,
        ValidationObserver
    },
    mixins: [formMixin],
    data() {
        return {
            formRef: 'supportFormRef',
            formData: {
                awsId: '',
                companyName: '',
                firstName: '',
                lastName: '',
                product: '',
                experiencing: '',
                expectation: '',
                email: '',
                isAgree: null
            },
            emailSubject: `Email from support page. ${this.$route.hash ? `(${this.$route.hash})` : ''}`
        };
    },
    computed: {
        productList() {
            return this.$store.state.products;
        }
    },
    mounted() {
        this.initLambda();
        this.selectProductByHash();
    },
    methods: {
        selectProductByHash() {
            this.formData.product = this.$route.hash
                ? this.productList.find(item => item.link === this.$route.hash.replace('#', '')).name
                : null;
        },
        getEmailTextBody() {
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const bodyRaw = [
                '\n',
                '- Product: ' + this.formData.product,
                '- Company Name: ' + this.formData.companyName,
                '- AWS Account ID: ' + this.formData.awsId,
                '- What issue are you experiencing?: ' + this.formData.experiencing,
                '- What was your expectation?: ' + this.formData.expectation,
                '- Time zone: ' + tz
            ];
            return bodyRaw.join('\n');
        }
    }
};
</script>
